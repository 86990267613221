@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
}

p,
div {
  cursor: default;
}


@layer components {
  /* area css */
  .line {
    @apply bg-neutral-200 w-full h-[1px] my-6;
  }

  .area-label {
    @apply flex items-center justify-between my-3 text-base;
  }

  .area-input-wrap {
    @apply flex items-end;
  }

  .area-input {
    @apply inline-block py-0.5 border-solid border-0 border-b border-neutral-300 outline-none focus:outline-none mr-3 max-w-[150px];
  }

  .area-toogle-slide {
    @apply w-12 h-6 transition-colors bg-red-400 rounded-full appearance-none cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-neutral-200 focus:ring-neutral-200;
  }

  .area-toogle-btn {
    @apply absolute w-4 h-4 mx-[5px] transition-transform transform bg-white rounded-full cursor-pointer;
  }

  input:checked {
    background-color: #60a5fa;
  }

  input:checked ~ span:last-child {
    --tw-translate-x: 1.5rem;
  }

  button:disabled > .icon polyline {
    stroke: rgb(209, 213, 219); /* neutral-300 */
  }
}

@keyframes logout-button-animation {
  0%,
  100% {
    transform: none;
    animation-timeing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  50% {
    transform: translateX(15%);
    animation-timeing-function: cubic-bezier(0.8, 0, 1, 1);
  }
}

.logout-button-bounce:hover {
  animation: logout-button-animation 1s infinite;
}


.tooltip::before {
  background-color: #737373;
  color: white;
}

.tooltip::after {
  border-color: transparent #737373 transparent transparent;
}

